import { Suspense, lazy, useMemo, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import getTheme from "../config/theme";
import { ThemeProvider, CssBaseline } from "@mui/material";

const Login = lazy(() => import("../pages/Login"));
const Cuenta = lazy(() => import("../content/cuentas/ContendioCuentas"));
const ContenidoInicio = lazy(() => import("../content/inicio/Dashboard/ContenidoInicio"));
const Inicio = lazy(() => import("../content/inicio/dashboard"));

const Ruta = () => {
  const [darkMode, setDarkMode] = useState(false);
  const theme = useMemo(() => getTheme(darkMode), [darkMode]);
  const handleThemeChange = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <Suspense fallback={<></>}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login isDarkMode={darkMode} />} />
            <Route path="/menu" element={<ContenidoInicio isDarkMode={darkMode} handleThemeChange={handleThemeChange}/>}>
              {/* rutas hijas */}
              <Route path="/menu/inicio" element={<Inicio isDarkMode={darkMode}/>} />
              <Route path="/menu/cuentas" element={<Cuenta isDarkMode={darkMode}/>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
};

export default Ruta;
