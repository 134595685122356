// eslint-disable-next-line no-restricted-globals
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AxiosInterceptor } from "./app/interceptors/axios.interceptors";
// import { store } from './app/redux/store';
import { Provider } from "react-redux";

AxiosInterceptor();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <App />
  </>
);
